import { Address, getAddress } from 'viem'

/**
 * these wallets can be scws or eoas, depending on what is registered with the orderbook
 */
export const MARKET_MAKER_ADDRESSES: Set<Address> = new Set(
  [
    '0x00395DD7E0480a8E38b9237693603994e5112ce9',
    '0x019fDD3a448f72eCa828a35eB35eDfD8C0e86CFB',
    '0x03f4e1646A15b888D9e351EFe34214Cb2D7cBfb1',
    '0x0bd5683e33329C1638BE89fc3B983f1820F982Be',
    '0xF4321cE4F7E813e4334466367f14f127EAf2B925',
    '0x4c7E2cbb3938aB31701416EdCDAefB14b10fBa47',
    '0x2a7D03C5891cB08ddAd1E811131EEf8401099d22',
    '0x2aD91a2ee1a1210077E39E41dE321752c1e9c35a',
    '0xA2CD2504E7B7Cf491D63AdBC587D6A859C8bDa20',
    '0xAA00c7B6F40168Ce785DBa191d1e34F04Dac1BC3',
    '0x3eD4ED6056C8e3549Bb873Ca964C060bB40b8ac0',
    '0x67185284e7eFF005d58816d64D85df6eAC5e0725',
    '0x46dE80db3024f90595DC867c7043cB110A9C75d5',
    '0xf7936fc302849a88E454d0c311C676c7F449821a',
    '0x4c7E2cbb3938aB31701416EdCDAefB14b10fBa47',
    '0x3b8D13d3f89ea04A4b92DC93E67025a11eCD7155',
    '0x55B3d64b865509a9BCE4956F5e21b25E91958dDb',
    '0x600158d017C3874A7Fa9FC7954DBaA3599A3cA42',
    '0x6425f64A067c184E198a4b18e25bB821EAc49282',
    '0x6B0fE721937ed61c6e261b5053D6178eCEf059a4',
    '0x8358D0e1468dD370CdaddAc1Ec1F1b85DfB675bc',
    '0x6dADEFCEde8D12b8E46707a0a6737c6C3688aF26',
    '0x6B0fE721937ed61c6e261b5053D6178eCEf059a4',
    '0x787Cbf841f03cdd5DcDD9E6DC3F2d1F0b6f999dA',
    '0x7B7E1b95ee230a0a2774c14d529236CF4000bf07',
    '0xF98B09e064dc36194618304B6b8d246813aE10d7',
    '0x9E8edbF9da32C33f8D1eD3F1Fc28e9F952FdE66C',
    '0x8358D0e1468dD370CdaddAc1Ec1F1b85DfB675bc',
    '0xC7283eA757954aE0278D7A6DFeD43f2F752123b5',
    '0x8C0066247e6E6421873F2DfEaCd7a9E3BcFaC5a8',
    '0x978e3eb7997a9575cdE1799176829073d2876Fb9',
    '0x019fDD3a448f72eCa828a35eB35eDfD8C0e86CFB',
    '0x9E8edbF9da32C33f8D1eD3F1Fc28e9F952FdE66C',
    '0xE1DC5576b36c78C75e6Abf2E1B121Db20A6069b7',
    '0xA2CD2504E7B7Cf491D63AdBC587D6A859C8bDa20',
    '0xb8382EF2f7A9a0471186fC969d25bb08Ed8Bc91b',
    '0xEAc2374016613765cF31afDB4AEB6f5d13a63726',
    '0xbbbc0fE4408ACb8B9c8c6cC782c93Ba8C791423D',
    '0xbfa7a2CD6ccd7EF8e77AA2181631b2abe61676f5',
    '0x46d8BbD89a592e850E509682b80B85Ede481426B',
    '0xE1c918C6e4bA3efCC9608333b321E0764B4612Dd',
    '0xE1DC5576b36c78C75e6Abf2E1B121Db20A6069b7',
    '0xE2657349db652953F2B05b092c59373200132d02',
    '0x978e3eb7997a9575cdE1799176829073d2876Fb9',
    '0xEAc2374016613765cF31afDB4AEB6f5d13a63726',
    '0xf248E91E8696dd286d492d907f725630E847FBce',
    '0xE2657349db652953F2B05b092c59373200132d02',
    '0xf7936fc302849a88E454d0c311C676c7F449821a',
    '0x88B6BB87fbFac92a34F8155aaA35c87B5b166fA9',
    '0x9cda2eb97A500c16F660F8a79126874c021bbb49',
    '0xCCfAf4103b371Dbc928EB182086c86B759e028Ed',
  ].map((address) => getAddress(address))
) // checksum

/**
 * these wallets can be scws or eoas, depending on what is registered with the orderbook
 */
export const REWARDS_BLOCK_ADDRESSES: Set<Address> = new Set(
  [
    '0x2ec5b0387251259df8513b21de5486e1263a849e',
    '0xcbb75d45e0e4962376603d8bc02119ba419f1c8b',
    '0xf194ddfed544f64d2dfc9522517cbb2fb8f2ff94',
    '0x5aed8f872097863504e498722074304310c48cdb',
    '0x9a07fb6b653556a1d22d78ba4f61eba53a60434f',
    '0xa50ca5f302de0b19fb00a58abee0f21e572e46d9',
    '0x0d83d3282bb866b38841c00aad6292d54ef1df5a',
    '0xe5abed32193332034688249f75a81460e45b3cfc',
    '0x95f6d444b5e8d2f8ae67b1f106518f49b7cff2c0',
    '0x55fa3c5ce58d5328334e58c9624586814e364034',
    '0x6b8c2445dcd87168ca513b5b8383fdf2a110c9f5',
    '0xe0d76cebf127f7733b75a16675982f8fa000311c',
    '0x13017873a81021fa566d3320a8cfa8c8b07961b6',
    '0x6dd356cd2274545a7ab8a6e815b537701bbd398e',
    '0x2e71c9f1da38bb20350ad272102331c5f9be388a',
    '0x2d574e8819de5105977d497c5aaf883bef5cdf48',
    '0x6dfdc78c25361a0be5f762b68df2528957cd45b2',
    '0x55e315d92b19e1d2c5c506b2d097f9710321910e',
    '0x61c1a0720b7f43509af53846486a8f329dcb942d',
    '0xeddcb87ec326ba3124923d7075dfd822e9381ec5',
    '0x850ba14846f7e153d6b8f40ee929d913f8f24c27',
    '0x2c3e4e6136ae724f5b0cec79eb186c91f266848c',
    '0x72a20c85e33f481e9962e8b2c4cbd3c7016d64fe',
    '0xfa0440361c45d38f710cc203c4e3cb43beb09591',
    '0xe94ee8eae85240c0b805181812135fc990bf3f36',
    '0x852135d75f0e45a37b7c6d915945673eb07d0e4d',
    '0x8af4e650e6a1c75a220c54c8e95ba3435417adc7',
    '0x1cd1c51dd5b95724e06594c884ecf9164a9d214b',
    '0x73bae53db72630cff266f05b7b64ceaa35abd410',
    '0xb7f26f3b60c99555f435c025f2f41159c1c99b35',
    '0x9e540547550083885fadf78d6580af48cbaccea4',
    '0x75d1d2db66f725113a564d8fc851dc5f7c820cdb',
    '0x728d9a8788cd3d7afeba21ccaaaec7a50d618b39',
    '0x6fb6b75a2fb703a0d16498f7274153eba70e65c9',
    '0xa80d05ff77984a7e9c2532695dc8cd15fe3ed3a8',
    '0xb92bfa55709c5f9a1d512e83e9559742d123f7fa',
    '0xeb873f270275443e8fb9a2d9f92fabde8a5539d0',
    '0x8c704b961e1ddf156ddeb41e1154055ea10cdc3c',
    '0x9a6959f05a7e409d439bf540b956f833cb2bb392',
    '0xa3c68766fbaba32d5c8013d5930d6be19551b8a7',
    '0xfc8d25ef6e06f9528ca31b5ad57e141cf43820bc',
    '0x59cd397bf67577117532efdb6e5dedb1b7855328',
    '0x09be489bfd63792d41dc8c910a82f7417e789966',
    '0x47ffb78bda817a5dcad458c948e641a6b258fbd0',
    '0xcc51ed8b5be03748fec8d92752f1b3c8f5a0cba7',
    '0x0f312a4c0e5d67cd9dff78e5d99568776ddf1e8a',
    '0xf3e027442b1a89bdab7c3c6ce0b3ec82a5a89298',
    '0xb859a91ebc8c63af5aec3243b962e1ac8000e47c',
    '0x3b2e22cb3ceac0f46521afe10e5af1acedca6edb',
    '0x09e3688c983c16ea8eac7c0fd063c5a73d5bb670',
    '0x1999e626eaaacb6fed1a8c128851af7c522b097e',
    '0x6a63aa8a8d8fd4dfc693c8d72d5473e6087b76c5',
    '0x37f306bbca0c8634e562cf7e168ad4b4ba699498',
    '0x7e7b46fcbe2440459f1581fd5921a3aada068da1',
    '0x8eb6c9cfda4b8258d1483d48643f813fcd8eb301',
    '0x8702f7f05d9b57eab3f7f2251e1bb9e2c318dd1a',
    '0x01bc9c60869a13f33025205749a96a4aa67bb91e',
    '0x320e44ea9450b1b78bffed2016148a1b312bb1d1',
    '0x2c0568a34d9ac43f72c652ea78ca00cf9a16a8a5',
    '0xd199a27677fba37df00599a70b848c3c65c0443e',
    '0xd6abbcd8d5d0c521f99d4443022a654b35f3430d',
    '0xcf4f9150c2aa2bad0f9f60b44a2a7ed0adefb034',
    '0xb43e330e62b408e82603a0a34cec55663bea5438',
    '0x9c2ec4ce082d821e51837f50ded19f7b8bd6d7ac',
    '0xb82de6f5679633160850498909f9fb6946038f43',
    '0xd79f148c427ce6c9d704dc0ebf70619cd6784a6d',
    '0x0c2d71459954cfe942810a4a2dde371c162eb7c6',
    '0x193729fdc2dc8d03a0ef486f350d356b8ebc38f3',
    '0xdf0fc62907743f0db40cd6ab003ee8ef986be4b3',
    '0xde4aaad8dc13fb8b0c977339fa3d1a9095862ab5',
    '0x5c496b5193b34355699a802eb068b9abd9f0b100',
    '0x4697d299988206d8e962b2faec24b657c5bfc103',
    '0xcd0838eae20c8a1bde4a52f42d50a3b254f5664f',
    '0x6ceb43f36b4c8fa23ffd4f3aaa372de10324cf0b',
    '0x237e67e068e3fc1e5e406586fffd5e7dbc06860d',
    '0xf2ba11936e52084255b053008796a8e596026783',
    '0x8eba5c680451692d5cbef01fbdd78409d2d5553a',
    '0x4f8b0f48da84a98cc09524f8e6e886a3eb6df627',
    '0x429c7fa8531fda34a3aff90ad979965f1d7c0b1a',
    '0x21decfc917f7971451c4a3f28bd74f43a13b86d7',
    '0xe4fdffe2b5f82736802db06dd216e36c05b465b4',
    '0xdb7b9ae67ed7df185319ff1352618b52b21e36c2',
    '0x0316e34b9b763edfe52f02e5160bf4040d38a7a0',
    '0xe4b362962410bd6e1757efcdf326d1e7eef416d1',
    '0x94486fdcf49712664e95262d20f88020595b2328',
    '0x843b0e70b3c90318afe0a952d346da0794a4917e',
    '0x075ab8bf695a24470a4e28c6640db34066d97b87',
    '0x6424400c047a9c5011f95ceaa7d84f989e298811',
    '0x180452c958bb7480e98a33e407085b75d03e5ea6',
    '0xd1678acb6a1e720ca527e92468fc771129af82e9',
    '0xfa70eed55113e57415ae4934eae05d67266b2295',
    '0x373d23fcdaa76f86bb37aa27687d4767d2b74d7b',
    '0x285cad9b5f53411ee1ac3b8131905f17c8fd43d2',
    '0x6bbe14a9a457e0f8a1397f8fd30c6053bb35ff42',
    '0x9a3ea3fc5db01ba35ee9cf971bb8f71419110c78',
    '0x97242bb3833f6fc700d3bbc84b5b5d3dc7d2528d',
    '0xc034710251ee3aea18492a09457f37c505be61dd',
    '0x144221995c8d204f300fa199e42cc9cede18397b',
    '0x1a78f417f135895969b516fa74fd9d83742799da',
    '0xcfcaf5001b9f00a349eebe9a1ff57c5fd5f85ec0',
    '0xca6b19d4b37c342c088381c3cb6864e1311c0d36',
    '0xf8c65d0ca615a70f639bf98828c57bc1c61dd630',
    '0xd36ea14c020e3799f2957df7fbfe7ecefa5621d6',
    '0x6b97c7826eca4d0c6e8a2ce11b2775a3d8bee384',
    '0x73daf4931b9e41ef2b19a247bf35b9e0119d184b',
    '0x663934e83e314a5c8976ade8d8e568531bd3fc15',
    '0x05369552305643c41b2adfb73f0cca7a86078ed3',
    '0x140ad42b40cf00864e1c9fb1ee489ce5bcb0cee9',
    '0x05a347632a4edf38c52370707f4e4972066266cc',
    '0x93034bb89db779d0fd16e69576af7d8a969f84b3',
    '0x96d5b1c0d0b0a2bc0551a4795adfdb757589f92e',
    '0x9eeaf78015dcbe3b919776e74f5b973cbf3c7995',
    '0xd41397fb0e374954a8815c483623609a1455cd4e',
    '0x1af04175af7bdb5261d3936cf19411a7b70766a3',
    '0x75fa16ac4c084afa6d69cf622a1848aedaab444f',
    '0x9f7d578e7297652e4f8f3f74fc5425eeccb4b015',
    '0x18971fd517b944c528bfb4962bdb0dc8647dc524',
    '0x42957b7134a0f833e57033ffe0c57d6a4ef132a2',
    '0xbf3cf67426b7d851ca7db53ae5814662d2b3d63b',
    '0xbd897b570f606effc5594a5a3f1f5f9e887b9768',
    '0xf808f328da0e4ec2e922fdeb9a29388f47a6cf05',
    '0x0dbd0cd242c768149cccb5c35b42c5af9d0e4267',
    '0x45fad9f2fd5dc96649b841eff84ebd36fb4a6afb',
    '0x6b9791479cc0fad8885cec5e1bda0bc34055c7a4',
    '0xac953108aa2e977b6284fde7bf3c23c5eba37784',
    '0x1fadca67725d9ec0b809db63b25a020fabf5d549',
    '0x41b213da56f091e0872e537f463c31197f67ad8a',
    '0x396a6b12ea7820cf8700e84366f1c087143f3018',
    '0xf04cf48da213fe3723ede7990eae29f5cf921169',
    '0x3672fb45498e135a7fee63bf5368db06ce659413',
    '0x9614f04886947ae777ff770bf610839ea0c576f2',
    '0x6f5c3ef381a37cd88b72d03f207edb1d4868cc7a',
    '0xa21280e7fb9527a3b7fbd7b714005927d11835e7',
    '0xc4d94ec169bdb9aa6c0965e705729573e5edd288',
    '0x3fe1b27ad1e60ddc06bb7bbb53a189bf354641f1',
    '0xe9a0151fbde73d6b426c90615f4f48ade1859225',
    '0xe31605cbeb913f2c3fd026bc74a4a1a1e5006bb9',
    '0x9f70bf067f13e7cccd6645a27f10cff1eecd8c63',
    '0x976fd89487dc64d3184e53702d2adb65de354be4',
    '0x57eb079aa95a932971859e3e8bfd64bf35f2d041',
    '0xcc08df86df150701df694682350cabefd545fbf6',
    '0xfbf86ad259e6b9e1e5fd5a36c1e19bcaac4cee20',
    '0xbcec45826750f5ca099feb670f7bd6656822b75e',
    '0x9c557f134dc0e20a61580cefcb5e6f4c634ededd',
    '0x35bbae012800d64120cefb91acadd5a4816912fc',
    '0x8af8e26982b88b0ec1db22111fb334ef1801d266',
    '0x1a73b9776ec763730b7e1ae6dfbb5625e09c5cd8',
    '0x16f59ab002bb71cea9b1cc82d5c3747671fddac8',
    '0xfe7824142220c2c559c1152792cacced501c3a9c',
    '0x7f033be768569d2747e590c332a2bc15338cb4d3',
    '0x8deff7380457e88991cb22b710604e88c23ae551',
    '0xdb2d79fd79c603ce4711130b645f5ee310ff21e3',
    '0x49193c5d4ec2f0c30a503c3df4df4cf97bc662e5',
    '0x0190003efc8faf4f339f69f76fd054046c7e9b3f',
    '0x7058ef81bb020f87e4912320a8960c4020c307dd',
    '0x8d7a6c711cb9670cb92d34217a0a2d315bc9e663',
    '0x6076314a8fae639372cdae229dfcad6380dd0c04',
    '0x9aef2580d6eaf089ec06ede4b59da76b08c196f7',
    '0xf3d4e93d840ac51208a3dfb0a0eb8e1584b9627c',
    '0x6d7f1cc2d09419828921de07858dce5b42ef7f54',
    '0xb56ea858011252cabe54e14f60b4cf79dbafe832',
    '0x536e2bcaef2b5642459475c0911c1d4f574b8a4f',
    '0x2854146d88eebaa5ccdd8cd094cca9218b670f89',
    '0x018b72d3ff327705777437770c8463c1b4d4e353',
    '0x7bdf3f763c8265dc74103e2fac2c39547f410c8f',
    '0x6dcff00e18d02fad9e49116d3daa4b5235d77731',
    '0x5b86432240631d06c4962605d4875f7a219e14ca',
    '0x17be207d8f18809d0520646c7b9ba380640112a4',
    '0x477915a1b7d1111b111796137e0df78263f60f91',
    '0x07acc1df007d94d9e61b248f14d970595ec08b7a',
    '0x943900ce9163acdb012423c2a7262e1b9af28458',
    '0x93b82a745b98c884d9405600dd01f61efa1dc813',
    '0x19fb7f5d45b8365126bc93eaf12c5daaefae2c5c',
    '0x830b1fb59721a6e7d313090b5cee86db237cfc3e',
    '0xb61c6cc8f48467f1e7efeefdd4bc7bb41715b317',
    '0xb220b3f090e33cfb62b617e40a55a9f39ba6786f',
    '0x1e61d0ba9d938dfa7342e7cb8c7c7ab6301a5449',
    '0xd8e24094d9f22814a66e60011be901c82ab490f0',
    '0xb1ce8bd0cfe9b65399eabb5b757d9a472481bc24',
    '0x364a2855d60b45d5c816ade40388533d9787679c',
    '0x389a435cd483b09b08b73a500641538e717efff1',
    '0x4b4bc9cf9f60cd693c585d31ead11f4754a366ca',
    '0x567539e2461963c2c694ba06b257a30a4d5e081c',
    '0x67009ced2b96cc597dfc8a55d9afa3c355fc7359',
    '0xa9428d0102c2da9006afa76ee3f9bf31ea2fb3b5',
    '0xa7a58c23ccbae5ae21ef544afbbf0fc412735074',
    '0x391a9aa08f4e293db75f7c1127c7cbf7c7f5493e',
    '0xaf1cd50cd0b28e30ac41b3f7b910b3cc0f3e4473',
    '0x17407fa6602c3ff3a9bb25763c07d24763002bd4',
    '0x60bc946d1b12209b8f37c325a0d0e0d3190da148',
    '0x2fde55dedc912d774f53e389952db51d7cb972cd',
    '0x93cb487efbaabc026935d4f5fa55c4f7579f10af',
    '0xd570be40916368c7b4d842401cc8e8014d4622c2',
    '0x07091ac81eb6e5a2d8ec2f8d741a90ddb13c1cc4',
    '0x4bf8067ba19ff294920e979fe49dd4e216630414',
    '0x7a270d109b818c5a1ce9ebbd5412a0da3d88c91d',
    '0xd42e2affa6aac282643237125f2c5acfc0b474f2',
    '0x6d1a148437e9e9c011dcf4b1f2ce70cc364afe64',
    '0x48f38c0c47a00aa0797e28f65398f5ab3c8ac522',
    '0x6df962868f2e4cbda9e572d12236090a4e60d20d',
    '0x8763bbc7b080de8d17cc3d4c5245f3109ee61d35',
    '0xc03bb338d021a0b1910f0f943f8185b634661aa8',
    '0xa62d90740985803babab819cb9e8753d852de2ba',
    '0xb376467fccecfe46308c905b40a03dbe2dcab2ac',
    '0xc2fcc738168a2cce30cd380a946d644518c90fb4',
    '0x6fc1bdede9453aefa5801cea46efbfc12c3fdd1f',
    '0xd9695cc546bf158c4748356ebcab62b6f2253b6c',
    '0xfc4bebe05edd33bbd82cdbdfacee5bcbad4a978a',
    '0x8043d398ea06b583bf2be89013d201f328cc75d5',
    '0xe0b2bf9fa8182281fa7b4f02c51f8b1f3725591f',
    '0x1feb30235fd2bce99a90dc179c8e067e5acf645d',
    '0x3c84b99a910ff05c4706be9a3663011092213dfb',
    '0x66f222a73e6d848f00ed7ab409a5c5d48dfb59e3',
    '0xbf4db6f4dd225402c64bb8e97cd9e8e1f8ce971a',
    '0x825b322337c74174fbffecb4e951986a75372af0',
    '0x7ab8766b9447f97dda7907c74365ce8a633d4ee0',
    '0xba6bc9a5527e11b948e2bd3678541b98d3363e2d',
    '0xd535e043d5f3d8818ceaa3fcf0d46e2f6820e63f',
    '0x5a9144aa022c633e90c1528a7656e308b3abd68e',
    '0xd2ca56c826ca40a98491202a10fba10a8222ca6b',
    '0xf5c4947fb00380df39e764055b2ea0eb94bb5b5e',
    '0x2b82510fe55e9de286387b0d699c811aacf2030d',
    '0xb6310eb4b2365b1f75c65997bfa414f49d28e8ba',
    '0x1519de815d6b6520c3bd8b2749884545bad54d7d',
    '0xbfce436dff888c6e3cafa84a016f0e848742bbba',
    '0xbd277579b2938631e080b531f7e74a015b393ee4',
    '0xe256ae2749d3771354e64ca870cbf574e2ad075b',
    '0xa59c32332df4f8c0df09440cecef3c705ad72cbc',
    '0x12cef86d6d51a4dbd81137e7ad198a5b9e473ca4',
    '0x25eefc2e89d0ce3a15313ecf7f86f785d43394bc',
    '0x585381d1b35dd722f35130bba80390d4541c3294',
    '0xe2e7d0f378702b88b616cf8d9b38348bc8eeb34e',
    '0xdea72f15a5bdef00a146073edd8e8a7ab12950e1',
    '0xff037b0ba2e33a23407b8bd56ffe695d867b35a0',
    '0x03fb68e00a3adae56ef0e9da654feeb3c03dad63',
    '0x67fddbd6fe4b63c042d55748c05b14abe8f288b4',
    '0xe42393b79a8e2cc3d11f4b797fb42d2d9a019aeb',
    '0x986e9a4260d4445afee96adce0321bf5752e0f86',
    '0xf3753bd897e3be52e0503985bbec89a214d14e72',
    '0xf76fe529fa9156455de07489c567db3773f3eb8e',
    '0x07c42c4412cc512e58c6c5641871f7567cb65476',
    '0x7b641bc756e28ef93a34811e7ef664673959cbca',
    '0x2aaa7c121c2110ba512e97c452a20652cea4afa4',
    '0x931987365204bb3a651850cc60e1ab170528d291',
    '0xcaafe9d035c98a247f403e00893a80a55e09f4fe',
    '0x8730eb30c5bca3e0fa3f3d03d6db44ff54c6f532',
    '0x5b53a04e9efbec2c4ba83429115a6eaff6bfef69',
    '0x75f7c576f29d0727042a2c5f6d626640267f55fb',
    '0x102bb4f352aacae57a0418368b1b3d5ce96ee3f3',
    '0x019397f7b5d981cd165e522e2892a1a4d83015d0',
    '0x22b0c0616fadf42c07f4e9241197cb8651fd1df3',
    '0x6cebbfe66f1c9a180018223f585649fac7486862',
    '0xed56c974fc2c51ff4e64b75a85d2f82a7b14d2c1',
    '0x5ec2ae7c92b613b43e6dee99ca00f57882c8ff78',
    '0x998aeecb663996f1002200a7fc21e3961b4cdc8c',
    '0x1c45a6bf1e63b592a0ce58b1cc7f0a89d0a7fe3a',
    '0x19fbfde1772a018243473eac97ebb9305ff70208',
    '0x320c8e875ce95a8651225fd918d0817ad450a3a5',
    '0x7becc3e4e586b94877ce8f97f468b6121830f122',
    '0xd1e7c735f23b44dcadfa525532272cf0fe4dae55',
    '0x3765d6f94edb8fc55cb3f53d0168cd467668871b',
    '0x7d34a9c54f4a71287e194955be9ccd443d6b536f',
    '0xff330db22a4c482b38c89829c62411ff37a86cb3',
    '0xf88dfe52cf8703d1e7e43612f08df6118f6a862b',
    '0x4705355038458585b57472c46ae1bf534d6d6f28',
    '0xa70689e524b23a9503f19067a3dddb973b40238b',
    '0x7d6d307095a4de2a03cdb8326aeb662388fd0f32',
    '0x47ea8b106341c59f65e537040defb7272938b78b',
    '0x4d91cbce2efa5762a14a36e609c9264c71440561',
    '0xc2bd1a3f935b1d64267764e641522a23a5008972',
    '0x772686e1432434e9235810c5bfc5c4bcdb110404',
    '0x119f990b9a4173d2899f071ba04f2621df9a617d',
    '0x0eefac7f67ee601d40d44904485999e051b172aa',
    '0x5ed1dfcf70d6b0d21f104c4ef9aee30ec4c07f50',
    '0x2f01e666949d6663099677ab9375013e1180774e',
    '0x9d7cb059248338a49693bae60f72790a357063b2',
    '0x91d49a80269928fb1d93b574b44f854cfa17f505',
    '0x1e504ae5cf01774ae89b898bcba4b33e76206258',
    '0x61bc8c441c10d9a9cfc864c5d0bdc9b8e37b462c',
    '0xdbb90e42619d04089fa6c2e97fe3311a67c7a154',
    '0xf00b51efcf52c1a22b5db07b379f8d37668c964e',
    '0xce07e61c09ab58357a27a29291680cb49ef1c609',
    '0xc9e3cc197fd2d471477612283946ed32fef814d0',
    '0x993f3b59c9ce7a96448ad726c9d87fd6808a5889',
    '0x7000127b3f27380d30018fca9881db55039a0bbc',
    '0x8c4f0d2d06c8de770fdaae0ba8e0cbab38c8d538',
    '0xc0ad83b6d9c459d4083ba724ebccd8a87541294a',
    '0x1a3b0b66bc680a937790f4408f2878a64e2fae54',
    '0x2870af438a1405e097ea25d091714b8b179b7003',
    '0xcdc9d7b78906d7a05611e18187c6729ecbc94b2c',
    '0x10ceb1f18b08c6941c3e820d8cdf47daab810971',
    '0xf72ecaeca62bf54a0542d892109d5fb8d1bf635a',
    '0x1ea6e66779f3bff2a950cd0aa7abb3e89093df62',
    '0x047d50d1bcb80f0b238b8778f9ce9b2cc613d414',
    '0x5811c4e7fc786e9d2202db46e219a7b4015caed6',
    '0x2d16c41c007de8f3b331618788893eba3b3dcf79',
    '0x838fff4d338b4e2cf1207abe83a091bd10cda884',
    '0xac3c4bb774e76d06df2709c93eb1268e9e55f0a5',
    '0x0a0f785323c6a6734e0d61e3a36cd12716c8e5ae',
    '0xcb682ffcb599339de1e981295441d9a3698f6fc9',
    '0xe267cf2881c8ea60e4e29885f20b628e00c184b8',
    '0x7df78577e7a4a0cb39e9b022604001f7ab13be7d',
    '0x333fcf3a4fb4365323e948c667778ceee512a061',
    '0xe1589225bf0a115dfd595cadd2e89c91afbdf014',
    '0x5422c66e06ab6ccd5843f66ea85ea4d87b383fc3',
    '0xed896f337c781b4645d5e29233f28dd4572756b2',
    '0x71500ee2cfc89b6b8ac0a9cdfe5dbba85185d8c6',
    '0xa4c6e072c19413ea9b4e54d4c3077e19c38272ea',
    '0x93f7929ba462e982135f02f84a5d3c15ba194bf9',
    '0x9e51c62fd127d9d6a0d137b3af3f193eae8cdb4d',
    '0x0614ff85db13c4f95e417ea49983da1ec06783e2',
    '0x2c68603abb9e960806ea91f9dc72036219fe17e8',
    '0x9e45bed8bf5592138fbf4385cc4a52f9cde4fc12',
    '0x6ca45de47cfb77996e713e4aca2ac0837b1512ae',
    '0x69cdca5299d2c55e9237ef11f3edc3b8b1d6c720',
    '0x347dd8fda1956583575cfaba48ac4e31a32fc744',
    '0x7d21b965e6e604c04f0885fa140a230c33c8938f',
    '0xf007fabe90e5679294b8afc08e247f45e95a388e',
    '0x9d2777e1573d71ce9fbaa1da3d916c6db7092501',
    '0x3f89fdbcaf6ae9cd69233c7ce357fb086cab9a61',
    '0x60d19de0e04da0a351ef40a0c514bd7589b647ab',
    '0x5fe1495eec64e90a66560e8bbd867565c5d351d3',
    '0x3692ce46fc79dbde2542174b5b7ad7952e0a301c',
    '0x0420d66c0a42639816f4826dd0d4903341b38200',
    '0xa381695adc5c6e8fd5857017d547ef3df4722156',
    '0x5fa7a802ede1ddc932798a1abb055a2333e22bac',
    '0x6623cfe80e7e1672de897131082554937c3669af',
    '0x0501b78f082fdb5a4ac0d1592d0b8f9032bef451',
    '0xf3d2716670b82dd69bb1218504132c0cc3ba093b',
    '0x57a3b1af05594a4e2374beea654a800075ec6b1e',
    '0xdc0b5375fa09eb384488fafe6640cd7ec9074715',
    '0x81aa13194aa8a470c348f02720394e79233d7adf',
    '0xd047cf235b28b546e457c9fb0b14dcdc29017715',
    '0xca36da299e020fb5c802f9f8896f7ed4d71e1f14',
    '0xec9b1bf8fdf39b33fe3f540a1fd91dee5bcb156e',
    '0x454ca42cf30d16a37aa37f488af0b4bb21759690',
    '0x6c3e553019d4c2db54d102c30e22c893f1274cb9',
    '0xeff6d97d45b93c1ef5c76f2932e749d9497af7cc',
    '0xbbb95720a66ad235eb9f0e8db1e92f4c08c71cca',
    '0x1e43dd838f1325d083868d83b4089c8da464fd12',
    '0x50a98de43288239b050b12b9143101bc6300eeb2',
    '0xac861ca58127a0b57aa7558dd3c3c9d125b8ef77',
    '0x6406cb161a29f29e45d54b69f737fd7f976f1a00',
    '0x0d83d0825ef241268edac49025c683bbfc6736ca',
    '0x58799111ead6794a8f264a15f2f89b2f20256fb5',
    '0x787bcc72b91bc7f670ef7d250d5280bb3ef2d0d9',
    '0x83ff1d939dd22444dc818740a98d6b256ccbc0bb',
    '0x85cc5b52bb9d5e18b44833efbf262bca205d83aa',
    '0xba270dd35177683390b7c5c2a289837a91d537c6',
    '0x3b50ab0c66fd470a0452cd2029948bd44991a88c',
    '0xd84d51ee98dfd32876356edd318777dce40c9251',
    '0xb86a7372229dcd2db46c62bee9687425bca03126',
    '0xb291e52502291fbca9a39cb4ee871c9b39f780b8',
    '0xe79a62263f7ca376175ed898fc2fe1ca361c0c3b',
    '0x5930ae50742d62e14eb486e35cbec65534ca0cb6',
    '0x356ed09abd95c646a690af746fb59a5c26f0d719',
    '0x3537c1a168c9bace1b10ef54dd9d911844ed4d46',
    '0x3d65361db185abc59f89d0d09bb71d2bcb4aff54',
    '0x37dac34e5b92f4cbd4d369f4960397f609990298',
    '0x37cb2fb7596902cb27e52547033083f9e16e060c',
    '0x15560a74d62a0547d9343e3058b960687539e91f',
    '0x4a6e4fef3acf9ffc8f9f3920c2207db83f1e63ee',
    '0xe537de364833bb9404280da552611ad3cb4b7dab',
    '0xa5012ed911a79b22ab6c39d7058e98985e5b2e66',
    '0xef19e2798f76680090be89a4218e0763eef902f9',
    '0xa9e3d8602861a026b4aeffdda931a7e903222633',
    '0xdf2f14a5086fd4837c8e35aab9b795ebc76741b6',
    '0x8c25020926b1617baa9d964df6d88d3a3245bfce',
    '0xb03c362479de62fa73bab4f35fbb02ba44aae0e3',
    '0x213de1ea9ba05bed4d7126e02c9220cc73648b4a',
    '0xd33fb02967baee8bf1a2468f93f5ee48afab7925',
    '0x02bb9be959da220c049c9028bc5d4a43bc4bf723',
    '0x4b508c83f0610f3f5205fba9b172e33a5a1dbb8b',
    '0xfc501be8934e6f3978e84d65e3061f8b68e1c04a',
    '0x900ef4ec00ff8109529ea700e4178d8f64bc33ca',
    '0x4a117c33c27acbe08af18bcf2158db3c537d5909',
    '0xe7f7ab854196d14f8788497ef35987b5147b830d',
    '0xfdc7e14a44f51d8e2771c42d6bf2d2908039f5ae',
    '0x270d9ba100122796d85e0887f335e7ff9311c1fb',
    '0xcdef7a2dd184dd3cc4460d37342f4779b2cf4450',
    '0xf4be2c4e6c235b877dbe2becad420ae308408191',
    '0x6015aadeaec2bd760febd6d04ce7b01c58616d90',
    '0x26431e359293ac6f92a6530a620767d5969d78bf',
    '0xcc6a7795c924e41b7b8cff984583aa175e709145',
    '0x61bfd630a2506aac17f7359e5784ff770e1d5ff7',
    '0xbb1692d9ae404d54e5e595a981d2a2d425b578eb',
    '0xf943cf2341d1b75234b808d22a0eae217335799c',
    '0x1f163db477292f16b28b2d885ae88839758f0d64',
    '0xc4b26fc31bb8657e720ce72aa1775d8b03ffc6cb',
    '0x2eabcdcbbfbaf8b796e819d93269b77450f06e10',
    '0x150a8cff352379237f1e707cd6a4adda36b71bd9',
    '0x1e88a6ee20da2f1e49123fc4fe920f7c242645f5',
    '0x00b023afdd31c900e5d4b02390595650ca670108',
    '0x9752c3afa26ce050a7671975933b4048ae619a95',
    '0x107bdeaa86075d0d04acfd3e3e25578a06f0c214',
    '0xecdd3c95185f84aca0d3b8bc21ca5834b555ae61',
    '0xe5ed7f4d36c27febf52dc99c78f6c05c9110f1be',
    '0x6308c7400b8a07d0db4a5fe1c51e8654e67996d7',
    '0x094b9f4c43876e2860637e80c088d6e4ba1e44d5',
    '0x4ba0109719381bb0461b157fb1ba6ecf73aa9c4a',
    '0x0d43fc741455ee4b21a7b2c12db70141cbf6bbf0',
    '0xbdc0ce7fd0f0c98fd84685f4206998fa54081002',
    '0x1bcbd1869fb99469af36f1c291152170e002aaf4',
    '0xb76878c57230767c53e4a80d93246553737fb122',
    '0xb6236109a9f653feebdae6ed1d9b440377d95a27',
    '0x56953c87402be8fe53fd8353621f0802ee37a0ac',
    '0xdb7663e61330c40b561d6a67d4c24ae6b762021b',
  ].map((address) => getAddress(address))
) // checksum

export const EBTC_DEPOSIT_ADDRESS_ALLOWLIST: Set<Address> = new Set(
  [
    '0x9cda2eb97A500c16F660F8a79126874c021bbb49',
    '0x2A5e5e23fdd0868D0c8c78Ddcf2958393bC900EE',
    '0xea21ed29F4339A5B7eEe409974e3E7c86404C85C',
  ].map((address) => getAddress(address))
) // checksum

export const CLIENT_BLOCK_ADDRESSES = new Set<Address>(
  [
    // /api/deposit endpoint spammer
    '0xC14cd16d8C96E17bA0558eb7D039a09Fba9B9a55',
  ].map((address) => getAddress(address))
)

// Override addresses for reward attribution - these must be SCW
export const REWARDS_ADDRESS_OVERRIDES = {
  '0x00A8CcC2cE0df5d603E0B75ac518c54d40544820': '0xc7D4d9C8205d353256ebeCC232B1370479E08428',
  '0xBa0512b8F70Cd73939F6b22e965950B977b372c5': '0xb9876D7F5e5d977EEdA80bD4a6C27ed0cf9C1a64',
}
